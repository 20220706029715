<template>
  <div class="guide-main pdHeader">
    <common-header class="red"></common-header>
    <div class="guide-form">
      <div class="guide-item flex centre3 mb6">
        <div class="fe input-box">
          <input
            type="text"
            placeholder="请输入手机号"
            class="input"
            maxlength="11"
            v-model="phone"
            @blur="fixScroll"
          />
        </div>
      </div>

      <div class="guide-item flex centre3 mb6">
        <div class="fe input-box">
          <input
            type="text"
            placeholder="请输入验证码"
            class="input"
            v-model="verifyCode"
            maxlength="6"
            @blur="fixScroll"
          />
        </div>
        <div class="yzm-box" @click="send">
          <span :class="`yzm ${verifyClass}`">{{ verifyText }}</span>
        </div>
      </div>

      <div class="guide-item flex centre3 mb6">
        <div class="fe input-box">
          <input
            type="password"
            placeholder="请输入密码"
            class="input"
            v-model="pwd"
            @blur="fixScroll"
          />
        </div>
      </div>
      <div class="guide-item flex centre3">
        <div class="fe input-box">
          <input
            type="password"
            placeholder="推荐人手机号(选填)"
            class="input"
            v-model="recommendPhone"
            @blur="fixScroll"
          />
        </div>
      </div>

      <a href="javascript:" class="guide-large-btn mt10" @click="register"
        >立即注册</a
      >

      <div class="fix guide-text">
        <div class="fl">
          已有账号，
          <router-link to="/guide/login" class="orange">立即登录</router-link>
        </div>
        <router-link to="/guide/forget" class="fr">忘记密码?</router-link>
      </div>

      <div class="login-method">
        <div class="line-text pre">
          <div class="centre2 box fix tc">
            <router-link to="/" class="enter">
              <span class="login-enter-icon"></span>
              <div class="txt">进入首页</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="register-agree tc">
      <div>
        <router-link to="/guide/agree?type=0">《网站服务协议》</router-link>
        <router-link to="/guide/agree?type=1">《个人信息保护政策》</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { isPhone, isCode, fixScroll } from "@/utils/utils";
import { getPreKey } from "@/utils/codeVerify";
import { setCookies, isWeiXin, authFun, noAuthConfig } from "@/utils/common";

let timer = 60;
let inter;
export default {
  name: "Register",
  data() {
    this.fixScroll = () => fixScroll();

    return {
      info: {},
      // 验证码需要 start
      verifyClass: "",
      verifyText: "获取验证码",
      phone: "",
      verifyCode: "",
      // 验证码需要 end
      referrerId: "",
      pwd: "",
      userNickName: "",
      recommendPhone: "",
      url: ""
      // 协议
      // checked: true,
    };
  },
  mounted() {
    const q = this.$route.query;
    if (isWeiXin() && !sessionStorage.getItem("code") && !noAuthConfig()) {
      const path =
        this.$route.fullPath.indexOf("?") === -1
          ? "?bind=register"
          : "&bind=register";
      const u = window.location.origin + this.$route.fullPath + path;
      authFun(u);
    }

    if (q.url) {
      this.url = decodeURIComponent(q.url);
    }
  },
  beforeDestroy() {
    // if (isWeiXin() && sessionStorage.getItem("code")) {
    //   sessionStorage.removeItem("code");
    // }
  },
  methods: {
    ...mapActions("guide", ["userRegister"]),
    // 获取验证码 start
    send() {
      if (this.verifyClass === "disabled") {
        return;
      }
      if (this.phone === "") {
        this.$toast("手机号不能为空");
        return;
      }
      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }

      this.sendTimer();
      inter = setInterval(this.sendTimer, 1000);

      getPreKey(
        {
          phone: this.phone,
          type: 1
        },
        v => {
          this.verifyCode = v.verifyCode || "";
        },
        () => {
          this.clearTimer();
        }
      );
    },
    clearTimer() {
      timer = 60;
      this.verifyClass = "";
      this.verifyText = "获取验证码";
      clearInterval(inter);
    },
    sendTimer() {
      timer--;
      if (timer < 1) {
        this.verifyClass = "";
        this.verifyText = "获取验证码";
        clearInterval(inter);
      } else {
        this.verifyClass = "disabled";
        this.verifyText = timer + "s";
      }
    },
    // 获取验证码 end
    register() {
      if (this.phone === "") {
        this.$toast("手机号不能为空");
        return;
      }
      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }

      if (this.verifyCode === "") {
        this.$toast("验证码不能为空");
        return;
      }
      if (!isCode(this.verifyCode)) {
        this.$toast("验证码格式不正确");
        return;
      }
      if (this.pwd === "") {
        this.$toast("密码不能为空");
        return;
      }

      let da = {
        phone: this.phone,
        pwd: this.pwd,
        uid: localStorage.getItem('uid'),
        referrerPhone: this.recommendPhone,
        verifyCode: this.verifyCode,
        referrerUserId: localStorage.getItem('referrerUserId') ? localStorage.getItem('referrerUserId') : '',
      };

      // 来源机构
      if (this.$route.query.originid) {
        da.originOrganizationId = this.$route.query.originid;
      }

      // 微信code
      if (sessionStorage.getItem("code")) {
        da.code = sessionStorage.getItem("code");
      }

      this.userRegister(da).then(res => {
        this.$toast("注册成功");
        sessionStorage.removeItem("code");
        setCookies(res.data);
        clearInterval(inter);
        const _this = this;
        setTimeout(() => {
          if (_this.url !== "") {
            // _this.$router.go(-1);
            _this.$router.replace(_this.url);
          } else {
            _this.$router.push("/");
          }
        }, 2000);
      });
    }
  }
};
</script>
<style lang="less" scoped>
// 协议
.mint-popup {
  border-radius: 0.1rem;
}

.register-agree {
  position: fixed;
  left: 0;
  bottom: 0.2rem;
  width: 100%;
  z-index: 10;
  color: #a1a1a1;
  font-size: 0.24rem;
  // margin-top: 2rem;
  line-height: 0.4rem;
  a {
    color: #a1a1a1;
  }
}

.enter {
  padding-top: 0.2rem;
  display: inline-block;
  margin: 0 0.3rem;
}
.login-enter-icon {
  display: inline-block;
  width: 1.2rem;
  height: 0.74rem;
  background: url("../../assets/images/img/login-enter-icon.png") no-repeat
    center center #fff;
  background-size: 0.74rem auto;
}

.login-weixin2-icon {
  display: inline-block;
  width: 1.2rem;
  height: 0.74rem;
  background: url("../../assets/images/img/login-weixin2-icon.png") no-repeat
    center center #fff;
  background-size: 0.74rem auto;
}

.login-method {
  margin-top: 1rem;
  padding-bottom: 1.2rem;
}
.login-method .txt {
  color: #9d9d9d;
  font-size: 0.24rem;
  text-align: center;
}
</style>
