<template>
  <div>
    <common-header></common-header>
    
    <div class="aft-details" v-if="details">
      <div class="warp">
        <!-- 订单相关 start -->
        <div class="order-info">
          <div class="item">
            <span class="l">订单编号:</span> 
            <span class="r">{{details.orderCode}}</span>
          </div>
          <div class="item">
            <span class="l">发货时间:</span>
            <span class="r">{{details.sendOutTime}}</span>
          </div>
          <div class="item">
            <span class="l">售后状态:</span>
            <span class="r">{{details.saleServiceEnable ? '售后期内' : '已过售后期'}}</span>
          </div>
          <div class="item">
            <span class="l">售后电话:</span>
            <span class="r"><a :href="`tel:${phone}`" style="color:#ff7241">{{phone}}</a></span>
          </div>
        </div>
        <!-- 订单相关 end -->
        <div class="commodity-info">
          <div class="commodity-title">
            <h1 class="title">下单时间: {{details.createTime}}</h1>
            <router-link v-if="!details.saleService && applyCount == 0 && details.saleServiceEnable" :to="`/account/afterform?type=3&orderid=${details.id}`" class="all-tui">退订单</router-link>
            <div class="request-order" v-else-if="details.saleService && details.saleService.type == 3">已申请退订单</div>
          </div>
          <div class="commodity-ul">
            <div class="commodity-item" v-for="(item2, index2) in details.orderDetail" :key="index2">
              <img class="commodity-img" :src="noImgSet(item2.image)" alt="">
              <div class="right">
                <div class="commodity-details">
                  <h4 class="name">{{item2.name}}</h4>
                  <p class="commodity-ms">{{item2.specName}}{{item2.seckillId ? '（秒杀）' : ''}}{{item2.remark?'（已改）':''}}</p>
                </div>
                <div class="commodity-num-box">
                  <div class="num">x{{item2.quantity}}</div>
                  <div class="operation-btn" v-if="!details.saleService && !item2.saleService && details.saleServiceEnable">
                    <div @click="clickOperation(item2.id,details.id,item2.directSaleServiceEnable, 1)" :to="`/account/afterform?id=${item2.id}&type=1&orderid=${details.id}`" class="retreat btn">退</div>
                    <div @click="clickOperation(item2.id,details.id,item2.directSaleServiceEnable, 2)" :to="`/account/afterform?id=${item2.id}&type=2&orderid=${details.id}`" class="change btn">换</div>
                  </div>
                  <div @click="$router.push(`/account/afterdetails?id=${item2.saleService.id}`)" class="operation-btn txt" v-else-if="item2.saleService">{{item2.saleService && item2.saleService.type == 1 ? '已申请退货' : '已申请换货'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 售后规则 start -->
        <div class="after-rules" v-if="news && news.content != ''">
          <div class="title">售后规则</div>
          <div class="content" v-html="news.content">

          </div>
        </div>
        <!-- 售后规则 end -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { requestImgUrl, isWeiXin, noImgSet, onBridgeReadyStart} from "@/utils/common";
import Register from '../guide/Register.vue';
export default {
  components: { Register },
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.orderStatus = (e) => orderStatus(e);
    this.noImgSet = (e) => noImgSet(e);
    return {
      details: '',
      news: '',
      applyCount: 0,
      phone: '',
    }
  },
  mounted() {
    this.init();

    this.getNewsList({
      typeCode: 'SALE_SERVICE',
    }).then((res)=> {
      console.log(res)
      this.news = res.data.list[0];
    })

    this.getPromoted().then((res)=> {
      // console.log(res)
      if (res.data.phone && res.data.phone != '') {
        this.phone = res.data.phone
      } else {
        this.getDictionary({
          dictCode: 'mallConfig',
          dataKey:'CS_PHONE'
        }).then((res2)=> {
          this.phone = res2.data.dataValue
        })
      }
    })
  },
  methods: {
    init() {
      this.$Indicator.open({
        text: '加载中...',
        spinnerType: 'snake',
      });
      let id = this.$route.query.id;
      this.getOrderAfter({
        orderId: id,
      }).then((res)=> {
        this.details = res.data;
        this.$Indicator.close();
        this.isAllTuiShow();
      });
    },
    clickOperation(id, orderid, directSaleServiceEnable, type) {
      if (!directSaleServiceEnable) {
        this.$router.push(`/account/afterform?id=${id}&type=${type}&orderid=${orderid}`)
      } else {
        this.$MessageBox({
          title: '温馨提示',
          message: `该商品支持无理由退换货，是否${type == 1? '退' : '换'}货？`,
          showCancelButton: true
        }).then((res)=> {
          if (res == 'confirm') {
            this.postSaleServiceApply({
              orderId: orderid,
              detailId: id || '',
              type: type
            }).then((msg)=> {
              this.init();
            })
          }
        })
      }
    },
    isAllTuiShow() {
      let items = this.details.orderDetail;
      let count = 0;
      for (var i = 0; i < items.length; i++) {
        if (items[i].saleService) {
          count++;
        }
      }
      this.applyCount = count;
    },
    ...mapActions('account', [
      'getOrderAfter',
      'getPromoted',
      'postSaleServiceApply',
    ]),
    ...mapActions([
      'getNewsList',
      'getDictionary',
    ])
  },
}
</script>
<style lang='less' scoped>
.request-order{
  color: #ff7241;
  font-size: .24rem;
}
.after-rules{
  background: #fff;
  border-radius: .1rem;
  margin-top: .2rem;
  padding: .2rem;
  .title{
    color: #000;
    font-weight: bold;
    font-size: .30rem;
    margin-bottom: .2rem;
  }
}
.operation-btn{
  display: flex;
  margin-top: .2rem;
  &.txt{
    font-size: .24rem;
    color: #ff7241;
  }
  .btn{
    font-size: .24rem;
    border: 1px solid #ff7241;
    color: #ff7241;
    padding: .1rem .15rem;
    margin: 0 .05rem;
    border-radius: .1rem;
  }
}
.commodity-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title{
    font-size: .28rem;
    color: #262626;
    font-weight: normal;
  }
  .all-tui{
    font-size: .24rem;
    border: 1px solid #ff7241;
    color: #ff7241;
    padding: .1rem;
    border-radius: .1rem;
  }
}
.commodity-info{
  background: #fff;
  padding: .2rem;
  margin-top: .2rem;
  border-radius: .1rem;
}
.aft-details{
  padding-top: .2rem;
  padding-bottom: .8rem;
}
.order-info{
  background: #fff;
  border-radius: .1rem;
  .item{
    padding: .2rem .28rem;
  }
  .l{
    font-size: .28rem;
    color: #262626;
    margin-right: .2rem;
  }
  .r{
    font-size: .28rem;
    color: #bfbfbf;
  }
}
</style>
